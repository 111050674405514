import React from 'react';
import { useDrag } from 'react-dnd';
import { Col } from 'react-bootstrap';
import './styles.scss';

const TextBox = ({ name, index, saveAnswer, manageSelectedTextBoxes, selected, cloudOnClickQuestions, centerText }) => {
  const [{ isDragging }, drag] = useDrag(() => ({
    type: 'TextBox',
    item: { name, index },
    end: (item, monitor) => {
      const dropResult = monitor.getDropResult();
      cloudOnClickQuestions(false);
      if (item && dropResult) {
        saveAnswer(dropResult.position, item.name);
        manageSelectedTextBoxes(index, dropResult.previousSelection);
      }
    },
    collect: monitor => ({
      isDragging: monitor.isDragging(),
      handlerId: monitor.getHandlerId()
    })
  }));

  let opacity = 1;
  if (isDragging) {
    opacity = 0.2;
  } else if (selected) {
    opacity = 0.6;
  }

  return (
    <>
      <Col ref={drag} style={{ opacity }} className="drag-text text-box" data-testid="box">
        <p className={`name ${centerText ? 'three-name' : ''}`}>{name}</p>
      </Col>
    </>
  );
};

export default TextBox;
