import React from 'react';
import { Container } from 'react-bootstrap';
import { ContentSlide } from 'react-presents';
import Footer from '../Footer';
import { Book } from '../Sound';
import './styles.scss';

const ImageSlide = ({ slideRef, page, nextPage, playSound }) => {
  const disable = page.next === undefined ? true : page.next;
  const handleClickImage = () => {
    if (page.pageType === 'image_sound' && !page.nextOnImage) {
      playSound(page.sound2);
    } else {
      slideRef.current.context.presentation.goForward();
      if (nextPage) playSound(nextPage.sound, nextPage.playOnLoad);
    }
  };
  return (
    <ContentSlide className="content-slide">
      {page.title !== undefined && (
        <div className="row header-container ">
          <div className="title col-sm-12" dangerouslySetInnerHTML={{ __html: page.title }} />
          {page.sound2 && page.pageType !== 'image_sound' && (
            <div>
              <Book playSound={playSound} soundURI={page.sound2} />
            </div>
          )}
        </div>
      )}
      <Container className={page.title !== undefined ? '' : 'm-t-50'}>
        <div className="image-content-container">
          <div
            className={`image-content ${!page.showBorders && 'hide-border'}`}
            onClick={handleClickImage}
            dangerouslySetInnerHTML={{ __html: page.content }}
            role="button"
            tabIndex={0}
            onKeyPress={() => {}}
          />
        </div>
        <Footer page={page} slideRef={slideRef} playSound={playSound} nextPage={nextPage} disabled={!disable} />
      </Container>
    </ContentSlide>
  );
};

export default ImageSlide;
