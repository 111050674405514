import React from 'react';
import { Container } from 'react-bootstrap';
import { ContentSlide } from 'react-presents';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik, withFormik, Field, Form } from 'formik';
import './styles.scss';
import { FormikSelect } from '../Utils/Select';
import { FormikInput } from '../Utils/Input';
import Footer from '../Footer';

const initialValues = {
  commune: '',
  establishment: '',
  course: '',
  name: ''
};

const FormSlide = ({ slideRef, communes, formRequest, page, nextPage, playSound }) => (
  <ContentSlide className="content-slide">
    <div className="title" dangerouslySetInnerHTML={{ __html: page.title }} />
    <Container>
      <div className="slide-form">
        <Formik initialValues={initialValues} onSubmit={values => formRequest(values)}>
          {({ setFieldValue, values, submitForm }) => {
            const disabled = Object.values(values).some(v => v === '');
            return (
              <Form>
                <Field name="commune">
                  {({ field }) => (
                    <FormikSelect
                      {...field}
                      options={communes}
                      onChange={value => setFieldValue('commune', value)}
                      label="Selecciona Comuna"
                      placeholder="Comuna"
                      isSearchable={false}
                    />
                  )}
                </Field>
                <Field name="establishment">
                  {({ field }) => (
                    <FormikSelect
                      {...field}
                      options={values.commune.schools}
                      onChange={value => setFieldValue('establishment', value)}
                      label="Selecciona Escuela"
                      placeholder="Escuela"
                      isSearchable={false}
                    />
                  )}
                </Field>
                <Field name="course">
                  {({ field }) => (
                    <FormikSelect
                      {...field}
                      options={values.establishment.courses}
                      onChange={value => setFieldValue('course', value)}
                      label="Selecciona Curso"
                      placeholder="Curso"
                      isSearchable={false}
                    />
                  )}
                </Field>

                <Field name="name">
                  {({ field }) => (
                    <FormikInput {...field} label="Escribe tu nombre y apellido" placeholder="Nombre y apellido" />
                  )}
                </Field>

                <Footer
                  onClick={submitForm}
                  page={page}
                  slideRef={slideRef}
                  playSound={playSound}
                  nextPage={nextPage}
                  disabled={disabled}
                  formSlide
                />
              </Form>
            );
          }}
        </Formik>
      </div>
    </Container>
  </ContentSlide>
);

const validationSchema = Yup.object().shape({
  commune: Yup.number().required('Obligatorio'),
  establishment: Yup.number().required('Obligatorio'),
  course: Yup.number().required('Obligatorio'),
  name: Yup.string().required('Obligatorio')
});

const mapStateToProps = state => {
  const { ongoingRequest, signedIn } = state.auth;
  return {
    ongoingRequest,
    signedIn
  };
};

export default withRouter(
  connect(mapStateToProps)(
    withFormik({
      mapPropsToValues: () => initialValues,
      validationSchema
    })(FormSlide)
  )
);
