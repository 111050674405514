const responseToBlob = response => {
  const format = response.headers['content-type'].split('/')[1];
  let blob = '';
  const { data } = response;
  if (format === 'xlsx') {
    const byteCharacters = atob(data);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i !== byteCharacters.length; i += 1) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    blob = new Blob([byteArray], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
  } else if (format === 'csv') {
    const readUTF8 = data.toString('utf8');
    blob = new Blob([readUTF8], { type: 'application/vnd.ms-excel' });
  } else {
    blob = new Blob([data], { type: response.headers['content-type'] });
  }
  return blob;
};

const parseFilename = headers => {
  let filename = '';
  const disposition = headers['content-disposition'];
  if (disposition && (disposition.indexOf('attachment') !== -1 || disposition.indexOf('inline') !== -1)) {
    const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
    const matches = filenameRegex.exec(disposition);
    if (matches != null && matches[1]) {
      filename = matches[1].replace(/['"]/g, '');
    }
  }
  return filename;
};

const downloadFile = response => {
  const blob = responseToBlob(response);
  const filename = parseFilename(response.headers);
  const url = window.URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = url;
  link.download = `${filename}`;
  link.click();
  window.URL.revokeObjectURL(url);
};

const shuffleArray = baseArray => baseArray.sort(() => Math.random() - 0.5);

const setCharAt = (str, index, chr) => {
  if (index > str.length - 1) return str + chr;
  if (index < 0) return str;
  return str.substring(0, index) + chr + str.substring(index + 1);
};

export default downloadFile;
export { shuffleArray, setCharAt };
