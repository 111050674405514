import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { ContentSlide } from 'react-presents';
import { isMobile } from 'react-device-detect';
import Footer from '../Footer';
import OnScreenKeyboard from '../OnScreenKeyboard';
import { setCharAt } from '../../services/utils';
import './styles.scss';

const ImageSyllablesSlide = ({
  slideRef,
  selected,
  onSelect,
  page,
  nextPage,
  playSound,
  cloudSelect,
  setCloudOnClick
}) => {
  const { questionNumber, image, title, alternatives } = page;
  // add 3 characters to every word on keyboard slides
  const [extendedAlternatives] = useState(
    alternatives
      .split(' ')
      .map(word => `${word}xyz`)
      .join(' ')
  );
  const [answer, setAnswer] = useState(
    extendedAlternatives
      .replace(/\w/g, String.fromCharCode(160))
      .replace('Ñ', String.fromCharCode(160))
      .split(' ')
  );
  const [_, setAnswerForSave] = useState(
    extendedAlternatives
      .replaceAll('xyz', '')
      .replace(/\w/g, '_')
      .replace('Ñ', '_')
      .split(' ')
  );

  const [selectedWord, setSelectedWord] = useState(0);
  const [selectedWordIdx, setSelectedWordIdx] = useState(0);

  const handleSelectWord = wordIdx => {
    setSelectedWord(wordIdx);
    const selectedWordLastSpaceIdx = answer[wordIdx].indexOf(String.fromCharCode(160));
    setSelectedWordIdx(selectedWordLastSpaceIdx >= 0 ? selectedWordLastSpaceIdx : answer[wordIdx].length);
  };

  const onKeyboardChange = pressedButton => {
    if (pressedButton === '{bksp}') {
      setAnswer(currentVal => {
        const newAnswer = [...currentVal];
        const newWord = setCharAt(newAnswer[selectedWord], selectedWordIdx - 1, String.fromCharCode(160));

        newAnswer[selectedWord] = newWord;
        return newAnswer;
      });
      setAnswerForSave(currentVal => {
        const newAnswerForSave = [...currentVal];
        const newWord = setCharAt(newAnswerForSave[selectedWord], selectedWordIdx - 1, '_');

        newAnswerForSave[selectedWord] = newWord;
        onSelect(questionNumber, newAnswerForSave.join(' '));
        return newAnswerForSave;
      });
      setSelectedWordIdx(currentWordIdx => Math.max(currentWordIdx - 1, 0));
    } else if (selectedWordIdx < answer[selectedWord].length) {
      setAnswer(currentVal => {
        const newAnswer = [...currentVal];
        const newWord = setCharAt(newAnswer[selectedWord], selectedWordIdx, pressedButton);
        newAnswer[selectedWord] = newWord;
        return newAnswer;
      });
      setAnswerForSave(currentVal => {
        const newAnswerForSave = [...currentVal];
        const newWord = setCharAt(newAnswerForSave[selectedWord], selectedWordIdx, pressedButton);
        newAnswerForSave[selectedWord] = newWord;
        // Remove extra spaces when saving answer
        onSelect(questionNumber, newAnswerForSave.join(' ').replace(/\s+/g, ' '));
        return newAnswerForSave;
      });
      setSelectedWordIdx(currentWordIdx => Math.min(currentWordIdx + 1, answer[selectedWord].length));
    }
  };

  useEffect(() => {
    if (cloudSelect) {
      setAnswer(
        extendedAlternatives
          .replace(/\w/g, String.fromCharCode(160))
          .replace('Ñ', String.fromCharCode(160))
          .split(' ')
      );
      setAnswerForSave(
        extendedAlternatives
          .replaceAll('xyz', '')
          .replace(/\w/g, '_')
          .replace('Ñ', '_')
          .split(' ')
      );
      setSelectedWord(0);
      setSelectedWordIdx(0);
    }
  }, [cloudSelect, extendedAlternatives]);
  return (
    <ContentSlide className="content-slide">
      <Container className={isMobile ? 'keyboard-top-mobile' : ''}>
        {(!!image || !!title) && (
          <Row>
            {!!title && <Col sm={12} className="title mobile-write" dangerouslySetInnerHTML={{ __html: title }} />}
            {!!image && <Col sm={12} className="title mobile-write" dangerouslySetInnerHTML={{ __html: image }} />}
          </Row>
        )}
        <Row className="align-middle">
          <Col className="mb-4 ml-3 d-flex flex-wrap justify-content-center answer-section">
            {answer.map((ansSp, idx) => {
              const isLast = idx === answer.length - 1;
              const marginWord = ansSp.replace(/\s+/g, ' ').length;
              return (
                // eslint-disable-next-line react/no-array-index-key
                <div>
                  <pre id="word" className="m-b-0 underscored" onClick={() => handleSelectWord(idx)}>
                    <p
                      style={{
                        paddingRight: `${marginWord > 4 ? 3 * Math.min(marginWord, 5) : 0}px`,
                        marginLeft: `-${marginWord > 4 ? Math.min(marginWord,6) * 2 : 1}px`
                      }}
                    >
                      {ansSp}
                    </p>
                  </pre>
                  {isLast ? null : <pre className="m-b-0">{String.fromCharCode(32)}</pre>}
                </div>
              );
            })}
          </Col>
        </Row>
        <Row className="fixed-above-footer" onClick={() => setCloudOnClick(false)}>
          <Col>
            <OnScreenKeyboard onKeyPress={onKeyboardChange} />
          </Col>
        </Row>
        <Footer
          page={page}
          slideRef={slideRef}
          playSound={playSound}
          disabled={!selected}
          nextPage={nextPage}
          selectAnswer={onSelect}
          cloudSelect={cloudSelect}
          cloudOnClickQuestions={setCloudOnClick}
        />
      </Container>
    </ContentSlide>
  );
};

export default ImageSyllablesSlide;
