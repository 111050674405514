import React from 'react';
import { Image } from 'react-bootstrap';
import logo from './RedSpeaker.png';
import './styles.scss';

const MainLogo = ({ soundURI, playSound }) => {
  return <Image src={logo} alt="Logo" className="speaker img-responsive" onClick={() => playSound(soundURI)} />;
};

export default MainLogo;
