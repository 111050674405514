import React from 'react';
import { Image } from 'react-bootstrap';
import logo from './Cloud.png';
import './styles.scss';

const Cloud = ({ onClick, selected }) => {
  const extraStyles = selected ? { filter: 'drop-shadow(3px 3px 3px blue)' } : {};
  const baseStyles = { padding: '10px' };
  return (
    <Image
      src={logo}
      alt="Logo"
      className="nav-img float-left cloud-mobile"
      style={{ ...baseStyles, ...extraStyles }}
      onClick={() => onClick && onClick()}
    />
  );
};

export default Cloud;
