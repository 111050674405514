import React, { useState } from 'react';
import { useDrop } from 'react-dnd';
import './styles.scss';

const DropContainer = ({ wordPosition, syllablePosition, saveAnswer, syllable }) => {
  const [droppedText, setDroppedText] = useState(String.fromCharCode(32));
  const [selectedItem, setSelectedItem] = useState();
  const [{ canDrop, isOver }, drop] = useDrop(
    () => ({
      accept: 'TextBox',
      drop: item => {
        const dropResult = {
          name: 'Dustbin',
          previousSelection: selectedItem,
          position: {
            word: wordPosition,
            syllable: syllablePosition
          }
        };
        setDroppedText(item.name);
        setSelectedItem(item.index);
        return dropResult;
      },
      collect: monitor => ({
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop()
      })
    }),
    [selectedItem]
  );

  const removeContent = () => {
    setDroppedText(String.fromCharCode(32));
    saveAnswer({ word: wordPosition, syllable: syllablePosition }, String.fromCharCode(32));
  };

  const isActive = canDrop && isOver;
  return (
    <div
      ref={drop}
      className={
        syllable ? 'container-thin container-text text container-thin-syllabus' : 'container-thin container-text text'
      }
      data-testid="dustbin"
    >
      <h1
        className={isOver ? 'text-large text-center box-over' : 'text-large text-center'}
        draggable
        onDragStart={removeContent}
        onClick={removeContent}
      >
        {isActive ? 'Soltar' : droppedText}
      </h1>
    </div>
  );
};

export default DropContainer;
