import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Form, ButtonToolbar, OverlayTrigger, Tooltip, Row } from 'react-bootstrap';
import Select from 'react-select';

const findDefaultValue = (value, defaultValue, options) => {
  if (defaultValue && value && defaultValue !== '' && value !== '' && value === defaultValue) {
    const vAttribute = options.filter(e => {
      return defaultValue === e.value;
    });
    return vAttribute[0];
  }
  return undefined;
};

const FormikSelect = ({
  options,
  label,
  abbr,
  customOption,
  error,
  touched,
  helpText,
  name,
  defaultValue,
  value,
  isDisabled,
  direction,
  tooltipText,
  delayShow,
  delayHide,
  ...props
}) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <Form.Group
      as={Row}
      controlId={label}
      className={`${isDisabled ? 'disabled' : ''} ${error && touched ? 'is-invalid' : ''}`}
    >
      {label && (
        <Form.Label column sm="5">
          {label} {abbr && <abbr className="text-danger ml-1">*</abbr>}
          {tooltipText && (
            <ButtonToolbar className="ml-2">
              <OverlayTrigger
                key={direction}
                placement={direction}
                delay={{ show: delayShow, hide: delayHide }}
                overlay={<Tooltip>{tooltipText}</Tooltip>}
              >
                ?
              </OverlayTrigger>
            </ButtonToolbar>
          )}
        </Form.Label>
      )}
      <div style={{ width: '300px' }}>
        <Select
          className={`${isOpen ? 'border-on ' : ''}`}
          placeholder="Buscar..."
          onMenuOpen={() => setIsOpen(true)}
          onMenuClose={() => setIsOpen(false)}
          loadingMessage={() => 'Cargando...'}
          noOptionsMessage={() => 'No hay opciones'}
          options={options}
          isSearchable
          name={name}
          value={findDefaultValue(value, defaultValue, options)}
          isDisabled={isDisabled}
          {...props}
        />
      </div>
      {error && touched && <Form.Text className="text-danger">{error}</Form.Text>}
      {helpText && <Form.Text className="text-muted">{helpText}</Form.Text>}
    </Form.Group>
  );
};

FormikSelect.propTypes = {
  direction: PropTypes.string,
  tooltipText: PropTypes.string,
  delayShow: PropTypes.number,
  delayHide: PropTypes.number,
  toolbarVariant: PropTypes.string
};

FormikSelect.defaultProps = {
  direction: 'top',
  delayShow: 250,
  tooltipText: '',
  delayHide: 0,
  toolbarVariant: ''
};

export default FormikSelect;
