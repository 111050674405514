import React from 'react';
import { Image } from 'react-bootstrap';
import logo from './Book.png';

const Book = ({ soundURI, playSound }) => {
  return (
    <div className="book">
      <Image src={logo} alt="Logo" className="speaker img-responsive" onClick={() => playSound(soundURI)} />
    </div>
  );
};

export default Book;
