import React from 'react';
import { Form, Row } from 'react-bootstrap';

const FormikInput = ({ placeholder, label, size, margin, error, touched, helpText, inputType, abbr, ...props }) => (
  <Form.Group as={Row} className={margin}>
    {label ? (
      <Form.Label column sm="5">
        {label} {abbr ? <abbr className="text-danger">*</abbr> : false}
      </Form.Label>
    ) : (
      false
    )}
    <div style={{ width: '300px' }}>
      <Form.Control
        size={size}
        placeholder={placeholder}
        type={inputType}
        className={error && touched ? 'is-invalid' : ''}
        {...props}
      />
    </div>
    {error && touched ? <Form.Text className="text-danger">{error}</Form.Text> : null}
    {helpText ? <Form.Text className="text-muted">{helpText}</Form.Text> : null}
  </Form.Group>
);

export default FormikInput;
