import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Button, ButtonToolbar } from 'react-bootstrap';

const DefaultModal = ({
  title,
  body,
  variantBtnClose,
  titleBtnClose,
  variantBtnSave,
  titleBtnSave,
  handleClose,
  handleConfirm,
  show,
  ...props
}) => (
  <Modal {...props} show={show} onHide={handleClose}>
    <Modal.Header closeButton>
      <Modal.Title>{title}</Modal.Title>
    </Modal.Header>
    <Modal.Body>{body}</Modal.Body>
    <Modal.Footer>
      <Button className="btn" variant={variantBtnClose} onClick={handleClose}>
        {titleBtnClose}
      </Button>
      <Button className="btn" variant={variantBtnSave} onClick={handleConfirm}>
        {titleBtnSave}
      </Button>
    </Modal.Footer>
  </Modal>
);

DefaultModal.propTypes = {
  title: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired,
  variantBtnClose: PropTypes.string,
  titleBtnClose: PropTypes.string.isRequired,
  variantBtnSave: PropTypes.string,
  titleBtnSave: PropTypes.string.isRequired,
  handleConfirm: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired
};

DefaultModal.defaultProps = {
  variantBtnClose: 'primary',
  variantBtnSave: 'success'
};

const CenteredModal = ({ header, title, body, titlebtn, size, onClickHide, ...props }) => (
  <Modal {...props} size={size} aria-labelledby="contained-modal-title-vcenter" centered>
    <Modal.Header closeButton>
      <Modal.Title id="contained-modal-title-vcenter">{header}</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <h4>{title}</h4>
      <p>{body}</p>
    </Modal.Body>
    <Modal.Footer>
      <Button onClick={onClickHide}>{titlebtn}</Button>
    </Modal.Footer>
  </Modal>
);

CenteredModal.propTypes = {
  header: PropTypes.string,
  title: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired,
  titlebtn: PropTypes.string.isRequired,
  size: PropTypes.string
};

CenteredModal.defaultProps = {
  header: null,
  size: 'lg'
};

class ModalCenter extends React.Component {
  constructor(...args) {
    super(...args);

    this.state = { modalShow: false };
  }

  modalClose = () => this.setState({ modalShow: false });

  render() {
    const { header, title, body, titlebtn, size, titlebtnmodal, onHide } = this.props;
    const { modalShow } = this.state;
    return (
      <ButtonToolbar>
        <Button variant="primary" onClick={() => this.setState({ modalShow: true })}>
          {titlebtnmodal}
        </Button>

        <CenteredModal
          show={modalShow}
          onHide={onHide}
          header={header}
          title={title}
          body={body}
          titlebtn={titlebtn}
          size={size}
          titlebtnmodal={titlebtnmodal}
        />
      </ButtonToolbar>
    );
  }
}

export { DefaultModal, ModalCenter };
