import React from 'react';
import { Modal, Row, Col } from 'react-bootstrap';

const BookModal = ({ handleReset, ...props }) => {
  const { show, onHide, body } = props;

  return (
    <Modal {...props} size="xl" show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>
          {body !== undefined && body !== null && (
            <div className="title col-sm-12" dangerouslySetInnerHTML={{ __html: body.title }} />
          )}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row className="content-book-modal d-flex justify-content-center mb-3">
          {body !== undefined && body !== null && (
            <div className="text-img-modal row d-flex" dangerouslySetInnerHTML={{ __html: body.image }} />
          )}
        </Row>
        <Row className="content-book-modal d-flex justify-content-center">
          <Col md={8} className="d-flex justify-content-center align-items-center">
            {body !== undefined && body !== null && (
              <div className="text-img row d-flex flex-column" dangerouslySetInnerHTML={{ __html: body.content }} />
            )}
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

export default BookModal;
