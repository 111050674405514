import React, { useState } from 'react';
import { useDrop } from 'react-dnd';
import './styles.scss';

const SmallDropContainer = ({ wordPosition, syllablePosition, saveAnswer }) => {
  const [droppedText, setDroppedText] = useState(String.fromCharCode(32));
  const [hoverText, setHoverText] = useState(String.fromCharCode(32));
  const [selectedItem, setSelectedItem] = useState();
  const [{ canDrop, isOver }, drop] = useDrop(
    () => ({
      accept: 'TextBox',
      drop: item => {
        const dropResult = {
          name: 'Dustbin',
          previousSelection: selectedItem,
          position: {
            word: wordPosition,
            syllable: syllablePosition
          }
        };
        setDroppedText(item.name);
        setSelectedItem(item.index);
        return dropResult;
      },
      hover: item => {
        setHoverText(item.name);
      },
      collect: monitor => ({
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop()
      })
    }),
    [selectedItem]
  );

  const isActive = canDrop && isOver;

  const removeContent = () => {
    setDroppedText(String.fromCharCode(32));
    saveAnswer({ word: wordPosition, syllable: syllablePosition }, String.fromCharCode(32));
  };

  return (
    <div ref={drop} className="container-thinner container-text text" data-testid="dustbin" draggable onDragStart={removeContent}>
      <div className={isOver ? 'text-large text-center box-over' : 'text-large text-center'}>
        {isActive ? hoverText : droppedText}
      </div>
    </div>
  );
};

export default SmallDropContainer;
