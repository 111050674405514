import React from 'react';
import { Switch } from 'react-router-dom';
import { PublicLayout } from './layouts';
import { Home } from '../screens';

const routes = (
  <Switch>
    <PublicLayout exact path="/" component={Home} />
    <PublicLayout exact path="/:id" component={Home} />
  </Switch>
);

export default routes;
