import React, { useState, useMemo, useRef, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { ContentSlide } from 'react-presents';
import { isMobile } from 'react-device-detect';
import Footer from '../Footer';
import { DropContainer, TextItem } from '../DragAndDrop';
import './styles.scss';

const SyllablesSlide = ({ slideRef, selected, onSelect, page, nextPage, playSound, cloudSelect, setCloudOnClick }) => {
  const calculateIsReady = (answers, fullLength) => answers.flat().filter(Boolean).length === fullLength;
  const { alternatives, questionNumber, image, otherInfo: unorderedLetters } = page;
  const parentRef = useRef();
  const [TILE_SIZE] = useState(() => (isMobile ? 44 : 64));
  const formattAlternative = typeof alternatives.flat()[0] === 'object' ? alternatives.flat() : alternatives;
  const [flattenedAlternatives] = useState(alternatives.flat().flat());
  const [randomizedAlternatives, setRandomizedAlternatives] = useState([]);
  const [answer, setAnswer] = useState(formattAlternative.map(word => new Array(word.length)));
  const [selectedSyllables, setSelectedSyllables] = useState(new Array(unorderedLetters.length).fill(0));
  const [overrideReady, setOverride] = useState(false);
  const isReady = useMemo(() => calculateIsReady(answer, flattenedAlternatives.length), [
    answer,
    flattenedAlternatives.length
  ]);
  let counterForFormat = -1;
  useEffect(() => {
    if (parentRef.current) {
      const heightTiles = Math.floor(parentRef.current.clientHeight / TILE_SIZE);
      const widthTiles = Math.floor(parentRef.current.clientWidth / TILE_SIZE);

      let randomNumberH;
      let randomNumberW;

      // Create blank matrix based on height/width dimensions
      const screenGrid = [...Array(heightTiles)].map(() => [...Array(widthTiles)]);

      const newRandomizedAlternatives = unorderedLetters.split('').map(elem => {
        // Search for blank space in matrix
        while (true) {
          randomNumberH = Math.floor(Math.random() * heightTiles);
          randomNumberW = Math.floor(Math.random() * widthTiles);

          if (!screenGrid[randomNumberH][randomNumberW]) {
            screenGrid[randomNumberH][randomNumberW] = 1;
            break;
          }
        }

        return {
          name: elem,
          top: randomNumberH * TILE_SIZE,
          left: randomNumberW * TILE_SIZE
        };
      });

      setRandomizedAlternatives(newRandomizedAlternatives);
    }
  }, [parentRef]);

  const saveAnswer = ({ word, syllable }, value) => {
    setAnswer(existingItems => {
      const newAnswer = [...existingItems];
      newAnswer[word][syllable] = value;
      onSelect(questionNumber, newAnswer.map(w => w.join('')).join(' '));
      return newAnswer;
    });
  };

  const manageSelectedTextBoxes = (currentSelection, previousSelection = null) => {
    if (currentSelection === previousSelection) return;

    setSelectedSyllables(currentValues => {
      const newValues = [...currentValues];
      newValues[currentSelection] += 1;

      if (previousSelection !== null) {
        newValues[previousSelection] -= 1;
      }

      return newValues;
    });
  };
  return (
    <ContentSlide className="content-slide">
      {!!image && (
        <Row className="header-container">
          <Col sm={12} className="title" dangerouslySetInnerHTML={{ __html: image }} />
        </Row>
      )}
      <Container className="fluid">
        <Row className="align-middle justify-content-center pt-4 pt-md-0" onClick={() => setCloudOnClick(false)}>
          <Col className="alternative justify-content-center">
            {alternatives.map((words, wordIdx) => {
              return (
                <Row
                  className={
                    typeof words[0] === 'object'
                      ? 'justify-content-center mb-2 mb-md-4 mx-0 row-separate-drop'
                      : 'justify-content-center mb-2 mb-md-4 mx-0 more-six-drop'
                  }
                >
                  {words.map((element, syllableIdx) => {
                    counterForFormat += 1;
                    return typeof element === 'object' ? (
                      element.map((_, syIdx) => {
                        const isLast = element.length - 1 === syIdx;
                        return (
                          <>
                            <Col xs={2} md={1} className="px-0 ">
                              <DropContainer
                                wordPosition={counterForFormat}
                                syllablePosition={syIdx}
                                saveAnswer={saveAnswer}
                                syllable
                              />
                            </Col>
                            {isLast ? <Col className="col-1" /> : null}
                          </>
                        );
                      })
                    ) : (
                      <Col xs={2} md={1} className="px-0">
                        <DropContainer
                          wordPosition={wordIdx}
                          syllablePosition={syllableIdx}
                          saveAnswer={saveAnswer}
                          syllable
                        />
                      </Col>
                    );
                  })}
                </Row>
              );
            })}
          </Col>
        </Row>
        <Row className="align-middle justify-content-center mx-0">
          <Col ref={parentRef} className="px-0" style={{ height: '300px', minHeight: '300px' }}>
            {randomizedAlternatives.map((alt, altIdx) => (
              <div style={{ position: 'absolute', top: alt.top, left: alt.left }}>
                <TextItem
                  name={alt.name}
                  index={altIdx}
                  saveAnswer={saveAnswer}
                  manageSelectedTextBoxes={manageSelectedTextBoxes}
                  selected={false}
                  cloudOnClickQuestions={setCloudOnClick}
                />
              </div>
            ))}
          </Col>
        </Row>
        <Footer
          page={page}
          slideRef={slideRef}
          playSound={playSound}
          disabled={!(isReady && selected) && !overrideReady}
          nextPage={nextPage}
          selectAnswer={onSelect}
          setOverride={setOverride}
          cloudSelect={cloudSelect}
          cloudOnClickQuestions={setCloudOnClick}
        />
      </Container>
    </ContentSlide>
  );
};

export default SyllablesSlide;
