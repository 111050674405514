import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import Raven from 'raven-js';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import store from './app/config/store';
import App from './App';
import './index.scss';

Raven.config('https://fac8b613207d4a6b83661ccc8072f44d@o549318.ingest.sentry.io/6249504').install();
Sentry.init({
  dsn: 'https://fac8b613207d4a6b83661ccc8072f44d@o549318.ingest.sentry.io/6249504',
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 1.0
});

const app = (
  <Provider store={store}>
    <App />
  </Provider>
);

ReactDOM.render(app, document.getElementById('root'));
