import React from 'react';
import PropTypes from 'prop-types';
import { Form, ButtonToolbar, OverlayTrigger, Tooltip } from 'react-bootstrap';
import RemoteSelect from './RemoteSelect';

const InputRemoteSelect = ({
  abbr,
  delayHide,
  delayShow,
  direction,
  disabled,
  error,
  helpText,
  label,
  onChange,
  request,
  tooltipText,
  touched,
  value,
  ...props
}) => (
  <Form.Group className={`${disabled ? 'disabled' : ''} ${error && touched ? 'is-invalid' : ''}`}>
    {label && (
      <Form.Label className="d-flex align-items-center">
        {label} {abbr && <abbr className="text-danger ml-1">*</abbr>}
        {tooltipText && (
          <ButtonToolbar className="ml-2">
            <OverlayTrigger
              key={direction}
              placement={direction}
              delay={{ show: delayShow, hide: delayHide }}
              overlay={<Tooltip>{tooltipText}</Tooltip>}
            >
              ?
            </OverlayTrigger>
          </ButtonToolbar>
        )}
      </Form.Label>
    )}
    <RemoteSelect value={value} loadOptions={request} onChange={onChange} disabled={disabled} {...props} />
    {error && touched && <Form.Text className="text-danger">{error}</Form.Text>}
    {helpText && <Form.Text className="text-muted">{helpText}</Form.Text>}
  </Form.Group>
)

InputRemoteSelect.propTypes = {
  direction: PropTypes.string,
  tooltipText: PropTypes.string,
  delayShow: PropTypes.number,
  delayHide: PropTypes.number,
  toolbarVariant: PropTypes.string
};

InputRemoteSelect.defaultProps = {
  direction: 'top',
  delayShow: 250,
  tooltipText: '',
  delayHide: 0,
  toolbarVariant: ''
};


export default InputRemoteSelect;
