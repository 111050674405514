import React from 'react';
import { Image } from 'react-bootstrap';
import logo from './BackArrow.png';
import './styles.scss';

const BackArrow = ({ onClick, disabled }) => (
  <Image
    src={logo}
    alt="Logo"
    className={`nav-img float-left arrows-mobile ${!disabled && 'arrows-mobile-only-two'} ${disabled && 'disabled-back-arrow '}`}
    onClick={() => !disabled && onClick && onClick()}
  />
);

export default BackArrow;
