import React, { useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { BackArrow, ForwardArrow } from '../Arrows';
import { Speaker } from '../Sound';
import Cloud from '../Cloud';
import BookModal from '../Utils/Modal/BookModal';
import Star from '../Star/Star';
import './styles.scss';

const Footer = ({
  page,
  slideRef,
  playSound,
  disabled,
  nextPage,
  onClick,
  formSlide,
  selectAnswer,
  setOverride,
  cloudSelect,
  cloudOnClickQuestions,
  otherInfo,
  book
}) => {
  const [selectedCloud, setSelectedCloud] = useState(cloudSelect);
  if (page.background) document.body.style.backgroundColor = page.background;
  const [showModal, setShowModal] = useState(false);
  const forwardArrowOnClick = () => {
    if (onClick) onClick();
    if (cloudOnClickQuestions) cloudOnClickQuestions(false);
    if (nextPage) playSound(nextPage.sound, nextPage.playOnLoad);
    setSelectedCloud(false);
    slideRef.current.context.presentation.goForward();
  };

  const cloudOnClick = () => {
    const { questionNumber } = page;
    // Set answer as blank
    selectAnswer(questionNumber, ' ');
    setSelectedCloud(true);
    if (cloudOnClickQuestions) cloudOnClickQuestions(true);
    if (setOverride) setOverride(true);
    // do onClick action for forward arrow
    // Action commented from feedback received
    // forwardArrowOnClick();
  };
  const handleOpenModal = () => {
    setShowModal(true);
  };
  const handleCloseModal = () => {
    setShowModal(false);
  };
  return (
    <Row className="fixed-bottom">
      <Col>
        <BackArrow disabled={!page.preview} onClick={() => slideRef.current.context.presentation.goBack()} />
      </Col>
      {selectAnswer && (
        <Col>
          <Cloud selected={cloudOnClickQuestions ? cloudSelect : selectedCloud} onClick={cloudOnClick} />
        </Col>
      )}

      <Col className="speaker-container">
        {!formSlide && page.sound && <Speaker playSound={playSound} soundURI={page.sound} />}
      </Col>
      {book && otherInfo !== null && (
        <Col className="d-flex justify-content-center">
          <Star handleOpenModal={handleOpenModal} />
        </Col>
      )}
      <Col className="d-flex content-align-center h-50">
        <ForwardArrow disabled={disabled} onClick={forwardArrowOnClick} isPreview={page.preview} />
      </Col>
      <BookModal show={showModal} onHide={handleCloseModal} body={otherInfo} />
    </Row>
  );
};

export default Footer;
