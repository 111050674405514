import React, { Component } from 'react';
import { ConnectedRouter } from 'connected-react-router';
import { connect } from 'react-redux';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { TouchBackend } from 'react-dnd-touch-backend';
import { isMobile } from 'react-device-detect';

import { history } from './app/config/store';
import routes from './app/config/routes';
import { Alert } from './app/components';
import GlobalErrorBoundary from './app/screens/Error/GlobalErrorBoundary';
import { getSettings } from './app/actions/utils';

import './index.scss';

class App extends Component {
  componentDidMount = () => {
    const { dispatch } = this.props;
    dispatch(getSettings());
  };

  render() {
    return (
      <DndProvider backend={isMobile ? TouchBackend : HTML5Backend}>
        <GlobalErrorBoundary>
          <ConnectedRouter history={history}>{routes}</ConnectedRouter>
          <Alert />
        </GlobalErrorBoundary>
      </DndProvider>
    );
  }
}

const mapStateToProps = state => {
  const { utils } = state.utils;
  return {
    utils
  };
};

export default connect(mapStateToProps)(App);
