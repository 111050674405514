import React from 'react';
import { Image } from 'react-bootstrap';
import logo from './ForwardArrow.png';

const ForwardArrow = ({ onClick, disabled, isPreview }) => {
  return (
    <Image
      src={logo}
      alt="Logo"
      className={`nav-img float-right arrows-mobile ${isPreview && 'arrows-mobile-only-two'}  ${disabled && 'disabled'}`}
      onClick={() => !disabled && onClick && onClick()}
    />
  );
};

export default ForwardArrow;
