import React from 'react';
import Keyboard from 'react-simple-keyboard';
import 'react-simple-keyboard/build/css/index.css';
import './styles.scss';

const OnScreenKeyboard = ({ onKeyPress }) => {
  const customLayout = {
    default: ['Q W E R T Y U I O P', 'A S D F G H J K L Ñ', 'Z X C V B N M {bksp}']
  };

  const nameDisplay = {
    '{bksp}': 'Borrar'
  };

  const buttonThemes = [
    {
      class: 'font-weight-bold backspace-background',
      buttons: '{bksp}'
    }
  ];

  return (
    <Keyboard
      onKeyPress={onKeyPress}
      layout={customLayout}
      display={nameDisplay}
      buttonTheme={buttonThemes}
      disableButtonHold
    />
  );
};

export default OnScreenKeyboard;
