import React, { useState, useMemo } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { ContentSlide } from 'react-presents';
import Footer from '../Footer';
import { DropContainer, TextBox } from '../DragAndDrop';
import './styles.scss';

const ImageSyllablesSlide = ({
  slideRef,
  selected,
  onSelect,
  page,
  nextPage,
  playSound,
  cloudSelect,
  setCloudOnClick
}) => {
  const calculateIsReady = (answers, fullLength) => answers.flat().filter(Boolean).length === fullLength;
  const { alternatives, questionNumber, image } = page;
  const [flattenedAlternatives] = useState(alternatives.flat());
  const [answer, setAnswer] = useState(alternatives.map(word => new Array(word.length)));
  const [selectedSyllables, setSelectedSyllables] = useState(new Array(flattenedAlternatives.length).fill(0));
  const [overrideReady, setOverride] = useState(false);
  const isReady = useMemo(() => calculateIsReady(answer, flattenedAlternatives.length), [
    answer,
    flattenedAlternatives.length
  ]);
  const saveAnswer = ({ word, syllable }, value) => {
    setAnswer(existingItems => {
      const newAnswer = [...existingItems];
      newAnswer[word][syllable] = value;
      onSelect(questionNumber, newAnswer.map(w => w.join('')).join(' '));
      return newAnswer;
    });
  };

  const manageSelectedTextBoxes = (currentSelection, previousSelection = null) => {
    if (currentSelection === previousSelection) return;

    setSelectedSyllables(currentValues => {
      const newValues = [...currentValues];
      newValues[currentSelection] += 1;

      if (previousSelection !== null) {
        newValues[previousSelection] -= 1;
      }

      return newValues;
    });
  };
  return (
    <ContentSlide className="content-slide">
      {!!image && (
        <Row className="header-container">
          <Col sm={12} className="title" dangerouslySetInnerHTML={{ __html: image }} />
        </Row>
      )}
      <Container className="image-syllables-container">
        <Row xs={flattenedAlternatives.length + 1} className="align-middle justify-content-center image-syllables mx-0">
          {alternatives.map((words, wordIdx) =>
            words.map((_, syllableIdx) => {
              const isLast = syllableIdx === words.length - 1 && wordIdx !== alternatives.length - 1;
              return (
                <>
                  <Col className=" box-image-syllables">
                    <DropContainer
                      wordPosition={wordIdx}
                      syllablePosition={syllableIdx}
                      saveAnswer={saveAnswer}
                      syllable={false}
                    />
                  </Col>
                  {isLast ? <Col className="col-1" /> : null}
                </>
              );
            })
          )}
        </Row>
        <Row
          className={`align-middle justify-content-center container-text-box${
            flattenedAlternatives.length === 3 ? ' three-text-box' : ''
          }`}
          xs={flattenedAlternatives.length + 2}
        >
          {flattenedAlternatives.map((alt, altIdx) => {
            return (
              <TextBox
                name={alt}
                index={altIdx}
                saveAnswer={saveAnswer}
                manageSelectedTextBoxes={manageSelectedTextBoxes}
                selected={false}
                cloudOnClickQuestions={setCloudOnClick}
                centerText={flattenedAlternatives.length === 3}
              />
            );
          })}
        </Row>
        <Footer
          page={page}
          slideRef={slideRef}
          playSound={playSound}
          disabled={!(isReady && selected) && !overrideReady}
          nextPage={nextPage}
          selectAnswer={onSelect}
          setOverride={setOverride}
          cloudSelect={cloudSelect}
          cloudOnClickQuestions={setCloudOnClick}
        />
      </Container>
    </ContentSlide>
  );
};

export default ImageSyllablesSlide;
