import React from 'react';
import { Container } from 'react-bootstrap';
import { ContentSlide } from 'react-presents';
import Confetti from 'react-confetti';
import congratulations from '../../assets/images/congratulations.png';
import './styles.scss';
import Footer from '../Footer';

const FinalSlide = ({ slideRef, page, playSound, sentAnswers }) => {
  return (
    <ContentSlide className="content-slide">
      {sentAnswers && <Confetti />}
      <Container>
        <div className="final-title" dangerouslySetInnerHTML={{ __html: page.title }} />

        <img className="final-img" alt="" src={congratulations} />

        <Footer page={page} slideRef={slideRef} playSound={playSound} disabled />
      </Container>
    </ContentSlide>
  );
};

export default FinalSlide;
