import React from 'react';
import { Container } from 'react-bootstrap';
import { ContentSlide } from 'react-presents';
import Footer from '../Footer';
import './styles.scss';

const CoverSlide = ({ slideRef, page, nextPage, playSound }) => (
  <ContentSlide className="content-slide">
    <Container>
      <div className="cover-title" dangerouslySetInnerHTML={{ __html: page.title }} />

      <Footer page={page} slideRef={slideRef} playSound={playSound} nextPage={nextPage} />
    </Container>
  </ContentSlide>
);

export default CoverSlide;
