import React from 'react';
import { Navbar } from 'react-bootstrap';
import { isMobile } from 'react-device-detect';
import MainLogo from '../MainLogo';

const PrincipalNavbar = ({ children }) => (
  <Navbar bg="transparent" expand="lg" fixed="top" className="space-between">
    {!isMobile && (
      <Navbar.Brand>
        <MainLogo />
      </Navbar.Brand>
    )}
    {children}
  </Navbar>
);

export default PrincipalNavbar;
