import React from 'react';
import { Container, Row } from 'react-bootstrap';
import { ContentSlide } from 'react-presents';
import Footer from '../Footer';
import { Book } from '../Sound';
import './styles.scss';
import NavQuestion from '../Navigation/NavQuestion.png';

const NavigationSlide = ({ slideRef, page, nextPage, playSound }) => (
  <ContentSlide className="content-slide">
    {page.title !== undefined && (
      <div className="row header-container ">
        <div className="title col-sm-12" dangerouslySetInnerHTML={{ __html: page.title }} />
        {page.sound2 && (
          <div>
            <Book playSound={playSound} soundURI={page.sound2} />
          </div>
        )}
      </div>
    )}
    <Container className={page.title !== undefined ? '' : 'top-container'}>
      <Row className="navigation-content-container">
        <div
          role="button"
          tabIndex={0}
          onKeyPress={() => {}}
          onClick={() =>
            slideRef.current.context.presentation.goToSlide({
              slideIndex: slideRef.current.context.presentation.getSlideIndex() - page.stepsBack
            })
          }
          dangerouslySetInnerHTML={{ __html: page.imageBack }}
        />
        {!page.withoutNext && (
          <div
            role="button"
            tabIndex={0}
            onKeyPress={() => {}}
            onClick={() => {
              if (nextPage) playSound(nextPage.sound);
              slideRef.current.context.presentation.goForward();
            }}
          >
            <img alt="" src={NavQuestion} />
          </div>
        )}
      </Row>
      <Footer page={page} slideRef={slideRef} playSound={playSound} nextPage={nextPage} />
    </Container>
  </ContentSlide>
);

export default NavigationSlide;
