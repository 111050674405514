import React, { useState, useMemo } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { ContentSlide } from 'react-presents';
import Footer from '../Footer';
import { SmallDropContainer, TextItem } from '../DragAndDrop';
import './styles.scss';

const EmbeddedLettersSlide = ({
  slideRef,
  selected,
  onSelect,
  page,
  nextPage,
  playSound,
  cloudSelect,
  setCloudOnClick
}) => {
  const { embeddedText, alternatives, questionNumber, image } = page;
  const calculateIsReady = answer => !answer.includes("*");
  const [fullText] = useState(embeddedText.split(' '));
  const [_, setAnswer] = useState(fullText);
  const [answerJoin, setAnswerJoin] = useState(fullText.join(' '));
  const [__, setSelectedLetters] = useState(new Array(alternatives.length).fill(0));
  const [overrideReady, setOverride] = useState(false);

  const isReady = useMemo(() => calculateIsReady(answerJoin), [answerJoin]);
  const renderTextWithComponent = () => {
    const textByWords = [...fullText];
    return textByWords.map((word, wordIdx) => {
      return (
        <div className="d-flex align-items-center">
          {word.split('').map((letter, letterIdx) => {
            if (letter === '*') {
              return (
                <div style={{ minWidth: '4vh', minHeight: '4vh', maxWidth: '4vh', maxHeight: '4vh' }}>
                  <SmallDropContainer wordPosition={wordIdx} syllablePosition={letterIdx} saveAnswer={saveAnswer} />
                </div>
              );
            }
            if (letter === ' ') {
              return String.fromCharCode(32);
            }
            return letter;
          })}
          {String.fromCharCode(160)}
        </div>
      );
    });
  };

  const saveAnswer = ({ word, syllable }, value) => {
    setAnswer(currentAnswer => {
      const newAnswer = currentAnswer.map(answerWord => answerWord.split(''));
      newAnswer[word][syllable] = value;
      const textAnswer = newAnswer.map(answerWord => answerWord.join(''));
      onSelect(questionNumber, textAnswer.join(' '));
      setAnswerJoin(textAnswer.join(' '));
      return textAnswer;
    });
  };

  const manageSelectedTextBoxes = (currentSelection, previousSelection = null) => {
    if (currentSelection === previousSelection) return;

    setSelectedLetters(currentValues => {
      const newValues = [...currentValues];
      newValues[currentSelection] += 1;

      if (previousSelection !== null) {
        newValues[previousSelection] -= 1;
      }

      return newValues;
    });
  };

  return (
    <ContentSlide className="content-slide">
      {!!image && (
        <Row className="header-container">
          <Col sm={12} className="title" dangerouslySetInnerHTML={{ __html: image }} />
        </Row>
      )}
      <Container>
        <Row className="align-middle justify-content-center">
          <Col className="d-flex justify-content-center">
            <pre className="d-flex flex-wrap h-100" style={{ fontSize: '4vh' }}>
              {renderTextWithComponent()}
            </pre>
          </Col>
        </Row>
        <Row className="align-middle justify-content-center mt-4">
          <Col className="d-flex justify-content-around h-100">
            {alternatives.map((alt, altIdx) => (
              <TextItem
                name={alt}
                index={altIdx}
                saveAnswer={saveAnswer}
                manageSelectedTextBoxes={manageSelectedTextBoxes}
                selected={false}
                extraClasses="as-circle"
                cloudOnClickQuestions={setCloudOnClick}
              />
            ))}
          </Col>
        </Row>
        <Footer
          page={page}
          slideRef={slideRef}
          playSound={playSound}
          disabled={!(isReady && selected) && !overrideReady}
          nextPage={nextPage}
          selectAnswer={onSelect}
          setOverride={setOverride}
          cloudSelect={cloudSelect}
          cloudOnClickQuestions={setCloudOnClick}
        />
      </Container>
    </ContentSlide>
  );
};

export default EmbeddedLettersSlide;
