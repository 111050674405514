import React from 'react';
import { Image } from 'react-bootstrap';
import logo from './star.png';
import './styles.scss';

const Star = ({ handleOpenModal }) => {
  return <Image src={logo} alt="Logo" className="star img-responsive" onClick={handleOpenModal} />;
};

export default Star;
