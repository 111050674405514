import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import camelCaseRecursive from 'camelcase-keys-recursive';
import { Presentation, Slide } from 'react-presents';
import snakeCaseKeys from 'snakecase-keys';
import Raven from 'raven-js';
import Sound from 'react-sound';
import { sendAlert } from '../../actions/utils';
import { quizRequest, sendAnswersRequest } from '../../requests/quizzes';
import {
  CoverSlide,
  FormSlide,
  QuestionSlide,
  ImageSlide,
  FinalSlide,
  TextSlide,
  KeyboardSlide,
  ImageSyllablesSlide,
  SyllablesSlide,
  NavigationSlide,
  EmbeddedLettersSlide
} from '../../components';
import primeroLee from '../../assets/images/primerolee.png';

class Home extends React.Component {
  constructor(props) {
    super(props);

    window.soundManager.setup({ debugMode: false });

    this.state = {
      ready: false,
      slides: [],
      pages: [],
      communes: [],
      answers: {},
      playStatus: Sound.status.STOPPED,
      soundURI: '',
      validationError: false,
      sentAnswers: false,
      cloudSelect: false
    };
    this.slideRef = React.createRef();
  }

  componentDidMount = () => {
    const {
      dispatch,
      match: {
        params: { id }
      }
    } = this.props;
    document.body.style.backgroundColor = '#f3d9da';

    window.addEventListener('hashchange', () => this.checkFinalSlide(this.currentHashPage()));

    quizRequest(id, {
      dispatch,
      successCallback: ({ data: { communes, name, template } }) => {
        this.setCommunes(camelCaseRecursive(communes));
        this.setSlides(camelCaseRecursive(template));
        this.setState({ name, ready: true });
      },
      failureCallback: () => this.setState({ ready: true })
    });
  };

  componentDidUpdate() {
    const { sentAnswers } = this.state;
    if (!sentAnswers) {
      this.validateData(this.currentHashPage());
    }
  }

  currentHashPage = () => Number(window.location.hash.split('/')[1]);

  checkFinalSlide = currentPage => {
    const { pages } = this.state;
    if (currentPage === pages.length - 1) this.sendAnswers();
  };

  playSound = (newURI, playOnLoad = true) => {
    this.setState(
      { playStatus: Sound.status.STOPPED, soundURI: newURI },
      () => playOnLoad && this.setState({ playStatus: Sound.status.PLAYING })
    );
  };

  setCommunes = communes => this.setState({ communes });

  sendAnswers = () => {
    const {
      dispatch,
      match: {
        params: { id }
      }
    } = this.props;
    const { answers } = this.state;
    const courseId = localStorage.getItem('courseId');
    const studentName = localStorage.getItem('studentName');
    try {
      this.setState({ sentAnswers: true });
      const params = {};
      params.quiz_student = { courseId, studentName };
      params.quiz_student.answersAttributes = Object.keys(answers).map(k => ({ question: k, alternative: answers[k] }));

      Raven.captureException('Enviando respuestas', { user: { params, courseId, studentName } });

      sendAnswersRequest(id, {
        params: snakeCaseKeys(params),
        dispatch,
        successCallback: () => {
          Raven.captureException('Exito!', { user: { params, courseId, studentName } });
          localStorage.removeItem('courseId');
          localStorage.removeItem('studentName');
        },

        failureCallback: () => {
          Raven.captureException('failure en sendAnswers', { user: { params, courseId, studentName } });
          dispatch(
            sendAlert({
              kind: 'error',
              timeout: 10000,
              message: 'Error al enviar las respuestas, debes responder la prueba de nuevo.'
            })
          );
          this.goToFirstSlide();
        }
      });
    } catch (error) {
      Raven.captureException('Faltan datos', { user: { courseId, studentName } });
      dispatch(
        sendAlert({
          kind: 'error',
          timeout: 10000,
          message: 'Error desconocido - Hay datos faltantes, debes responder la prueba de nuevo.'
        })
      );
      this.goToFirstSlide();
    }
  };

  setSlides = ({ pages }) => {
    const slides = [];
    pages.forEach(page => {
      switch (page.pageType) {
        case 'cover':
          slides.push(CoverSlide);
          break;
        case 'form':
          slides.push(FormSlide);
          break;
        case 'text_type':
          slides.push(TextSlide);
          break;
        case 'navigation':
          slides.push(NavigationSlide);
          break;
        case 'finished':
          slides.push(FinalSlide);
          break;
        case 'image':
        case 'image_sound':
          slides.push(ImageSlide);
          break;
        case 'keyboard':
          slides.push(KeyboardSlide);
          break;
        case 'syllables':
          slides.push(ImageSyllablesSlide);
          break;
        case 'syllables_letters':
          slides.push(SyllablesSlide);
          break;
        case 'embedded_letters':
          slides.push(EmbeddedLettersSlide);
          break;
        default:
          slides.push(QuestionSlide);
          break;
      }
      this.setState({ pages, slides });
    });
  };

  setFormData = ({ course, name }) => {
    localStorage.setItem('courseId', course.value);
    localStorage.setItem('studentName', name);
  };

  onSelect = (q, a) => {
    const { answers } = this.state;
    answers[q] = a;
    this.setState({ answers });
  };

  validateData = page => {
    const { dispatch } = this.props;
    const { pages, answers, validationError } = this.state;
    const courseId = localStorage.getItem('courseId');
    const studentName = localStorage.getItem('studentName');
    if (pages.length) {
      let questionQty = 0;
      for (let i = 0; i < page; i += 1) {
        if (pages[i].questionNumber) {
          questionQty = pages[i].questionNumber;
        }
      }
      if (
        this.currentHashPage() > 1 &&
        !validationError &&
        (Object.keys(answers).length < questionQty || !courseId || !studentName)
      ) {
        this.setState({ validationError: true }, this.goToFirstSlide);
        localStorage.removeItem('courseId');
        localStorage.removeItem('studentName');
        Raven.captureException('Datos faltantes en validateData', { user: { courseId, studentName } });
        dispatch(
          sendAlert({
            kind: 'error',
            timeout: 10000,
            message: 'Hay datos faltantes, debes responder la prueba de nuevo.'
          })
        );
      }
    }
  };

  setCloudOnClick = value => {
    this.setState({ cloudSelect: value });
  };

  goToFirstSlide() {
    const { current } = this.slideRef;
    if (current) {
      this.setState({ validationError: false, sentAnswers: false });
      current.context.presentation.goToSlide({ slideIndex: 0 });
    } else {
      this.goToFirstSlide();
    }
  }

  render() {
    const {
      match: {
        params: { id }
      }
    } = this.props;
    const { answers, slides, communes, name, pages, soundURI, playStatus, ready, sentAnswers } = this.state;
    if (!ready || (id && slides.length)) {
      return (
        <>
          <Sound
            url={soundURI || ''}
            playStatus={playStatus}
            onFinishedPlaying={() => this.setState({ playStatus: Sound.status.STOPPED })}
          />
          <Presentation disableTouch disableTheme>
            {slides.map((Component, index) => {
              return (
                <Slide
                  // eslint-disable-next-line react/no-array-index-key
                  key={index}
                  ref={this.slideRef}
                  render={() => (
                    <Component
                      slideRef={this.slideRef}
                      page={pages[index]}
                      nextPage={pages[index + 1]}
                      selected={answers[pages[index].questionNumber]}
                      name={name}
                      communes={communes}
                      formRequest={this.setFormData}
                      onSelect={this.onSelect}
                      playSound={this.playSound}
                      sentAnswers={sentAnswers}
                      cloudSelect={this.state.cloudSelect}
                      setCloudOnClick={this.setCloudOnClick}
                    />
                  )}
                />
              );
            })}
          </Presentation>
        </>
      );
    }

    return (
      <div className="not-found">
        <h1>No se encontró la evaluación, intenta nuevamente.</h1>
        <img alt="" src={primeroLee} />
      </div>
    );
  }
}

const mapStateToProps = state => {
  const { slides } = state.utils;
  return {
    slides
  };
};

export default connect(mapStateToProps)(withRouter(Home));
