import React from 'react';
import { useDrag } from 'react-dnd';
import './styles.scss';

const TextItem = ({ name, index, saveAnswer, manageSelectedTextBoxes, selected, cloudOnClickQuestions, extraClasses = '' }) => {
  const [{ isDragging }, drag] = useDrag(() => ({
    type: 'TextBox',
    item: { name, index },
    end: (item, monitor) => {
      const dropResult = monitor.getDropResult();
      cloudOnClickQuestions(false);
      if (item && dropResult) {
        saveAnswer(dropResult.position, item.name);
        manageSelectedTextBoxes(index, dropResult.previousSelection);
      }
    },
    collect: monitor => ({
      isDragging: monitor.isDragging(),
      handlerId: monitor.getHandlerId()
    })
  }));

  let opacity = 1;
  if (isDragging) {
    opacity = 0.2;
  } else if (selected) {
    opacity = 0.6;
  }

  return (
    <div ref={drag} style={{ opacity }} className={`drag-text text-item ${extraClasses}`} data-testid="box">
      {name}
    </div>
  );
};

export default TextItem;
