import ApiService from '../services/apiService';

export const quizRequest = (id, { dispatch, params = {}, failureCallback, successCallback }) =>
  ApiService.request('get', `/quizzes/${id}`, {
    dispatch,
    params,
    failureCallback,
    successCallback
  });

export const sendAnswersRequest = (id, { dispatch, params, formData, failureCallback, successCallback }) =>
  ApiService.request('post', `/quizzes/${id}/quiz_students`, {
    dispatch,
    params,
    formData,
    failureCallback,
    successCallback
  });
