import React from 'react';
import { Container, Row } from 'react-bootstrap';
import { ContentSlide } from 'react-presents';
import Footer from '../Footer';
import { Book } from '../Sound';
import './styles.scss';

const TextSlide = ({ slideRef, page, nextPage, playSound }) => (
  <ContentSlide className="content-slide">
    {page.title !== undefined && (
      <div className="row header-container">
        <div className="title col-sm-12 mt-5" dangerouslySetInnerHTML={{ __html: page.title }} />
        {page.sound2 && (
          <div>
            <Book playSound={playSound} soundURI={page.sound2} />
          </div>
        )}
      </div>
    )}
    <Container className={page.title !== undefined ? '' : 'top-container'}>
      <Row xs={1} lg={2} className="align-middle justify-content-center">
        <div
          className={`text-content col-lg-${page.content2 ? '8' : '12'}`}
          dangerouslySetInnerHTML={{ __html: page.content }}
        />
        {page.content2 && (
          <div
            className="text-img col-lg-4 d-flex"
            dangerouslySetInnerHTML={{ __html: page.content2 }}
          />
        )}
      </Row>
      <Footer page={page} slideRef={slideRef} playSound={playSound} nextPage={nextPage} />
    </Container>
  </ContentSlide>
);

export default TextSlide;
