import React from 'react';
import { Route, Redirect, Link } from 'react-router-dom';
import { Container, NavDropdown } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { NavbarLogged, PrincipalNavbar } from '../components';
import { requestSignOut } from '../actions/auth';

export const LoggedLayout = ({ component: Component, ...rest }) => {
  const dispatch = useDispatch();
  return (
    <Route
      {...rest}
      render={props =>
        localStorage.getItem('jwt') ? (
          <>
            <NavbarLogged>
              <NavDropdown.Item as={Link} to="/slides">
                Slides
              </NavDropdown.Item>
              <NavDropdown.Item onClick={() => dispatch(requestSignOut())}>Cerrar Sesión</NavDropdown.Item>
            </NavbarLogged>
            <Container fluid>
              <Component {...props} />
            </Container>
          </>
        ) : (
          <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
        )
      }
    />
  );
};

export const NonLoggedLayout = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      localStorage.getItem('jwt') ? (
        <Redirect to={{ pathname: '/home', state: { from: props.location } }} />
      ) : (
        <>
          <Container fluid>
            <Component {...props} />
          </Container>
        </>
      )
    }
  />
);

export const PublicLayout = ({ component: Component, ...rest }) => {
  const dispatch = useDispatch();
  return (
    <Route
      {...rest}
      render={props =>
        localStorage.getItem('jwt') ? (
          <>
            <NavbarLogged>
              <NavDropdown.Item as={Link} to="/slides">
                Slides
              </NavDropdown.Item>
              <NavDropdown.Item onClick={() => dispatch(requestSignOut())}>Cerrar Sesión</NavDropdown.Item>
            </NavbarLogged>
            <Container fluid>
              <Component {...props} />
            </Container>
          </>
        ) : (
          <>
            <PrincipalNavbar />
            <Container fluid>
              <Component {...props} />
            </Container>
          </>
        )
      }
    />
  );
};
