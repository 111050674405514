import React from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import { ContentSlide } from 'react-presents';
import Footer from '../Footer';
import './styles.scss';

const QuestionSlide = ({ slideRef, selected, onSelect, page, nextPage, playSound, cloudSelect, setCloudOnClick }) => {
  const { otherInfo } = page;
  return (
    <ContentSlide className="content-slide">
      <div className="row header-container">
        <div className="title col-sm-6 image-question" dangerouslySetInnerHTML={{ __html: page.title }} />
      </div>
      <Container>
        <Row
          xs={page.alternativeType === 'text' ? 1 : 2}
          md={2}
          lg={4}
          className="align-middle justify-content-center"
          onClick={() => setCloudOnClick(false)}
        >
          {page.alternatives.map(alt => (
            <Col
              key={alt.alternativeNumber}
              className="mb-4 alternative"
              onClick={() => {
                onSelect(page.questionNumber, alt.alternativeNumber);
              }}
            >
              {page.showOption && (
                <h2 className={`alt-number-${page.borderWidth} ${selected === alt.alternativeNumber && 'nn-selected'}`}>
                  {alt.alternativeNumber}
                </h2>
              )}
              <div
                className={`${selected === alt.alternativeNumber && 'nn-selected'} option-${page.borderWidth} ${
                  page.alternativeType === 'text' ? 'option-text' : ''
                } ${page.alternativeType}`}
                dangerouslySetInnerHTML={{ __html: alt.image }}
              />
              <p className={`alt-caption-${page.borderWidth}`}>{alt.text}</p>
            </Col>
          ))}
        </Row>
        <Footer
          page={page}
          slideRef={slideRef}
          playSound={playSound}
          disabled={!selected}
          nextPage={nextPage}
          selectAnswer={onSelect}
          cloudSelect={cloudSelect}
          cloudOnClickQuestions={setCloudOnClick}
          otherInfo={otherInfo}
          book
        />
      </Container>
    </ContentSlide>
  );
};

export default QuestionSlide;
